import {Component, computed, input, output} from '@angular/core';
import {CommonModule} from "@angular/common";

type ButtonType = ['primary', 'info', 'warning', 'danger'];

@Component({
    selector: 'save-button',
    templateUrl: './save-button.component.html',
    imports: [CommonModule],
    standalone: true
})
export class SaveButtonComponent {
    save = input.required<boolean>();
    type = input<ButtonType>();

    protected readonly btnClass = computed(() => this.type() ? `btn-${this.type()}` : 'btn-primary');

    saving = output<void>();
}
