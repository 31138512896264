<button type="submit" class="btn" [ngClass]="btnClass()" (click)="saving.emit()">
  @if (save()) {
    <span class="spinner-grow spinner-grow-sm me-1" role="status" aria-hidden="true">
      <span class="visually-hidden">Saving...</span>
    </span>
    <ng-container i18n>Saving...</ng-container>
  } @else {
    <ng-container i18n>Save</ng-container>
  }
</button>
